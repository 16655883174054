import { Component, Prop } from 'vue-property-decorator';
import BaseCadastro from '@/components/base-components/BaseCadastro';
import UsuariosModel from '@/models/UsuariosModel';
import UsuariosService from '@/services/usuarios.service';
import LogService from '@/services/log.service';
import OutputError from '@/models/output/OutputError';

@Component
export default class CadUsuarios extends BaseCadastro {

    @Prop() readonly data: any;

    public edicao: boolean = false;
    public model: UsuariosModel;
    public title: string = 'Cadastro de cidades';

    created() {
        this.model = UsuariosModel.fromData(this.data.usuario);
        this.edicao = this.data.edicao;
    }


    async salvar() {
        this.clearErros();
        this.showLoading(true);
        try {
            const output = await UsuariosService.salvar(this.model)

            if (!output.isValid) {
                this.showSnackBar("Não foi possível salvar o registro.");

                this.addErros(output.errorMessages);
                return;
            }

            this.showSnackBar("Registro salvo com sucesso.");
            this.voltar();

        } catch (error) {
            LogService.Error("Usuarios", "salvar", error)
            this.addErro(new OutputError({ message: "Erro ao salvar o dado" }))
            this.showSnackBar("Erro ao salvar o registro");
        }
        finally {
            setTimeout(() => {
                this.showLoading(false)
            }, 500);
        }
    }

    voltar() {
        setTimeout(() => {
            this.$router.back();
        }, 1500);
    }


}

