export enum DateFormatType {
    DD_MM_YYYY,
    DD_MM_YYYY_HH_MM_SS,
}

export default class DataHelper {

    public static convertUnixTimeStampToDateFormat(unixTimeStamp: number | Date, type: DateFormatType) {
        // Create a new JavaScript Date object based on the timestamp
        // multiplied by 1000 so that the argument is in milliseconds, not seconds.
        const date = new Date(unixTimeStamp);

        return this.formatDateByType(date, type)
    }

    public static formatDateByType(pDate: Date, type: DateFormatType) {

        const date = new Date(pDate);
        
        const day = date.getDate().toString().padStart(2, "0");
        const month = date.getMonth() + 1;
        const monthStr = month.toString().padStart(2, "0");
        const year = date.getFullYear();

        // Hours part from the timestamp
        const hours = date.getHours();
        // Minutes part from the timestamp
        const minutes = "0" + date.getMinutes();
        // Seconds part from the timestamp
        const seconds = "0" + date.getSeconds();

        let formattedTime: string = "";

        switch (type) {
            case DateFormatType.DD_MM_YYYY:
                formattedTime = `${day}/${monthStr}/${year}`;
                break;
            case DateFormatType.DD_MM_YYYY_HH_MM_SS:
                formattedTime = `${day}/${monthStr}/${year} ${hours}:${minutes.substr(-2)}:${seconds.substr(-2)}`;
                break;
        }

        return formattedTime;
    }
}
