
export default class OutputError{
    property : string | undefined
    message: string | undefined
    value: any | undefined
    
    constructor(init?: Partial<OutputError>) {
        if (init) {
          Object.assign(this, init);
        }
      }
}