import AnuncioModel from "@/models/AnuncioModel";
import CategoriaModel from "@/models/CategoriaModel";
import CidadeModel from "@/models/CidadeModel";
import Output from "@/models/output/Output";
import TipoAnuncioModel from "@/models/TipoAnuncioModel";
import IPesquisaDadosCommand from "@/models/types/IPesquisaDadosCommand";
import { ApiService } from "./api.service";
import CategoriaService from "./categorias.service";
import CidadeService from "./cidades.service";
import LogService from "./log.service";
export default class AnunciosService {


    public static async consultarPorId(id: number): Promise<Output<AnuncioModel>> {
        try {            
            const url = `/api/v1/dado/${id}`;
            return await ApiService.get(url);
        } catch (err) {
            LogService.Error("AnunciosService", "consultarPorId", err)
            return Output.newOutputErrorMessage<AnuncioModel>(null, "Não foi possível consultar o registro por id")
        }
    }

    public static async consultar(command: IPesquisaDadosCommand): Promise<Output<AnuncioModel[]>> {
        try {            
            const url = `/api/v1/dado/consultar-dados`;
            return await ApiService.post(url, command);
        } catch (err) {
            LogService.Error("AnunciosService", "consultar", err)
            return Output.newOutputErrorMessage<AnuncioModel[]>(null, "Não foi possível consultar os registros")
        }
    }

    public static async salvar(model: AnuncioModel): Promise<Output<AnuncioModel>> {
        try {
            if (model.id == undefined) {
                return await this.add(model)
            }
            return await this.put(model)
        }
        catch (err) {
            LogService.Error("AnunciosService", "salvar", err)
            return Output.newOutputErrorMessage<AnuncioModel>(null, "Não foi possível salvar o registro")
        }
    }

    private static async add(model: AnuncioModel): Promise<Output<AnuncioModel>> {

        const url = `/api/v1/dado/`;
        return await ApiService.post<AnuncioModel>(url, model);
    }

    private static async put(model: AnuncioModel): Promise<Output<AnuncioModel>> {

        const url = `/api/v1/dado/${model.id}`;
        return await ApiService.put<AnuncioModel>(url, model);
    }

    public static async deletar(id: number): Promise<Output<boolean>> {
        try {            
            const url = `/api/v1/dado/${id}`;
            return await ApiService.delete<boolean>(url);            
        } catch (err) {
            LogService.Error("AnunciosService", "deletar", err)
            return Output.newOutputErrorMessage<boolean>(false, "Não foi possível excluir o registro")
        }
    }

    public static async deletarImagem(imagem: string): Promise<Output<boolean>> {
        try {
            if (!imagem) {
                return Output.newOutputErrorMessage<boolean>(false, "Nome da imagem não informada");
            }            
            const url = `/api/v1/dado/delete-upload-anuncio/${imagem}`;
            return await ApiService.delete<boolean>(url);                 
        } catch (err) {
            LogService.Error("AnunciosService", "deletar", err)
            return Output.newOutputErrorMessage<boolean>(false, "Não foi possível excluir o registro")
        }
    }

    public static async consultarCidades(): Promise<Output<CidadeModel[]>> {
        try {
            return await CidadeService.consultar("");
        } catch (error) {
            LogService.Error("AnunciosService", "consultarCidades", error)
            return Output.newOutputErrorMessage<CidadeModel[]>([], "Não foi possível consultas as cidades")
        }
    }

    public static async consultarCategorias(incluirTodas: boolean): Promise<Output<CategoriaModel[]>> {

        try {
            const output = await CategoriaService.consultar("");
            if (incluirTodas) {
                const catTodos = new CategoriaModel();
                catTodos.id = 0;
                catTodos.nome = "Todas";
                if(output.result){
                    output.result.unshift(catTodos);
                }
            }
            return output;
        } catch (error) {
            LogService.Error("AnunciosService", "consultarCategorias", error)
            return Output.newOutputErrorMessage<CategoriaModel[]>([], "Não foi possível consultas as categorias")
        }
    }

    public static consultarTiposAnuncios(): TipoAnuncioModel[] {
        var tipoAnuncios = Array<TipoAnuncioModel>();
        tipoAnuncios.push(new TipoAnuncioModel(0, "Todos"));
        tipoAnuncios.push(new TipoAnuncioModel(1, "Relação normal"));
        tipoAnuncios.push(new TipoAnuncioModel(2, "Banner"));
        tipoAnuncios.push(new TipoAnuncioModel(3, "Mídias sociais"));
        tipoAnuncios.push(new TipoAnuncioModel(4, "Topo pesquisa"));
        tipoAnuncios.push(new TipoAnuncioModel(5, "Rodapé pesquisa"));
        tipoAnuncios.push(new TipoAnuncioModel(6, "Capa"));

        return tipoAnuncios;
    }
}
