import { Component, Vue } from 'vue-property-decorator';
import InputRules from "@/utils/InputRules"
import BaseComponent from './BaseComponent';

@Component
export default class BaseCadastro extends BaseComponent {
    
    public rules = InputRules.rules;
    public formValido:boolean = true    
        
    created(){                
    }
    
}

