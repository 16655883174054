
import { Component, Vue } from "vue-property-decorator";
import Config from "@/config/global"

@Component
export default class Logout extends Vue {
  mounted() {
    localStorage.removeItem(Config.USER_LOCAL_STORAGE);
    this.$router.push("/login");
  }
}
