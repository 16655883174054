interface ILoginModel{
    id: number | null;    
    email: string;
    senha: string;    
    token: string;  
}
/**
 * User model.
 */
export default class LoginModel {

    
    public id: number | null;    
    public email: string;
    public senha: string;    
    public token: string;    
    
    /**
     *
     */
    constructor() {        
    }

    
    public static setLoginModel(data: ILoginModel) {
        const loginModel = new LoginModel();
        loginModel.id = data.id;        
        loginModel.email = data.email;
        loginModel.senha = data.senha;
        loginModel.token = data.token;

        return loginModel;
    }
}