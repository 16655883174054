import CidadeModel from '@/models/CidadeModel';
import CidadeService from '@/services/cidades.service';
import { Component, Prop } from 'vue-property-decorator';
import BaseCadastro from '@/components/base-components/BaseCadastro';
import LogService from '@/services/log.service';
import OutputError from '@/models/output/OutputError';

@Component
export default class CadCidades extends BaseCadastro {

    @Prop() readonly data: any;

    public model: CidadeModel;
    public title: string = 'Cadastro de cidades';    
    private  newItem:boolean = false;

    created() {
        this.model = { ...new CidadeModel(), ...this.data.item };
        this.newItem = this.data.newItem;  
    }

    async salvarOnClick() {
        this.clearErros();
        this.showLoading(true);
        try {
            const output = await CidadeService.salvar(this.newItem, this.model);
            
            if (!output.isValid) {
                this.showSnackBar("Não foi possível salvar o registro.");                
                this.addErros(output.errorMessages);
                return;
            }

            this.showSnackBar("Registro salvo com sucesso.");
            this.voltar();
        } catch (error) {
            LogService.Error("CadCidades", "salvar", error)
            this.addErro(new OutputError({ message: "Erro ao salvar o dado" }))
            this.showSnackBar("Erro ao salvar o registro");
        }
        finally{
            setTimeout(() => {
                this.showLoading(false);
            }, 500);
        }
    }

    voltar() {
        setTimeout(() => {
            this.$router.back();
        }, 1500);
    }


}

