import { LoginResul } from "@/models/LoginResult";
import Output from "@/models/output/Output";
import ILoginCommand from "@/models/types/ILoginCommand";
import { ApiService } from "./api.service";
import LogService from "./log.service";

export default class AuthService {

    public static async login({ email, senha }: ILoginCommand): Promise<Output<LoginResul>> {

        try {
            const url = `/api/v1/auth/login`;
            const data = {
                email: email,
                senha: senha
            };
            return await ApiService.post(url, data)
        } catch (err) {
            LogService.Error("AuthService", "login", err)
            return Output.newOutputErrorMessage<LoginResul>(null, "Não foi possível acessar o sistema") 
        }
    }
}
