/**
 * User model.
 */
export default class CidadeModel {


    public id: number | undefined;
    public nome: string | undefined;
    public ddd: number | undefined;


    constructor(init?: Partial<CidadeModel>) {
        if (init) {
            Object.assign(this, init);
        }
    }

}