import { Component } from 'vue-property-decorator';
import BaseCadastro from '@/components/base-components/BaseCadastro';
import AnuncioModel from '@/models/AnuncioModel';
import AnunciosService from '@/services/anuncios.service';
import CategoriaModel from '@/models/CategoriaModel';
import CidadeModel from '@/models/CidadeModel';
import TipoAnuncioModel from '@/models/TipoAnuncioModel';
import ImagemHelper from '@/utils/imagem-helper';
import LogService from '@/services/log.service';
import OutputError from '@/models/output/OutputError';
import IUploadImage from '@/models/types/IUploadImage';

@Component
export default class CadAnuncios extends BaseCadastro {

    //#region Properties
    private id: number | undefined;
    public model: AnuncioModel = new AnuncioModel();
    public title: string = 'Cadastro de anúncios';

    public categoriaSel: CategoriaModel = new CategoriaModel();
    public categorias: Array<CategoriaModel> = [];

    public cidadeSel: CidadeModel = new CidadeModel();
    public cidades: Array<CidadeModel> = [];

    public tipoAnuncioSel: TipoAnuncioModel = new TipoAnuncioModel();
    public tipoAnuncios: Array<TipoAnuncioModel> = []

    public fileInput: any = {};
    public fileName: string = "";
    public fileBlob: string = "";
    public fileBase64: string = "";
    //#endregion

    async created() {
        this.id = (this.$route.query.id) ? Number(this.$route.query.id) : undefined;

        await this.carregarAnuncio();
        this.carregarTipoAnuncio();
        await this.carregarCidades();
        await this.carregarCategorias();        
    }

    //#region UI Events
    public get imagemOnUI() {

        LogService.Info("imagemOnUI")

        if (this.fileBase64 != "") {
            return this.fileBase64;
        }
        return this.model.imagem ? this.model.imagemFullPath : '';
    }

    excluirImagemOnClick() {
        this.clearErros();
        this.clearImageFields();

        return;

        // const result = confirm(`Deseja realmente excluir esta imagem? O processo é irreversível!`);
        // if (result == false) {
        //     return;
        // }

        // if (this.model.id == undefined) {
        //     this.limparCamposImagem();
        //     return;
        // }

        // if (this.model.id != undefined && this.model.imagem == undefined) {
        //     this.showSnackBar("Imagem não localizada.");
        //     return;
        // }

        // this.excluirArquivoUpload();
    }

    async fileInputOnChange(value: File) {

        this.clearErros();
        try {
            LogService.Info("fileInputOnChange", value)

            if (value == undefined || !value.name) {           
                this.clearImageFields();                     
                return false
            }
            this.fileName = value.name;

            const imagemResized = await ImagemHelper.resizeImage(this.fileInput, 1024, 360);
            this.fileBase64 = await ImagemHelper.blobToBase64(imagemResized);            

        } catch (error) {
            LogService.Error("CadAnuncios", "fileInputOnChange", error)
            this.addErro(new OutputError({ message: "Erro ao carregar a imagem" }))
        }
    }

    async salvarOnClick() {
        try {
            const uploadResult = await this.createUploadImagem();
            await this.salvarDados(uploadResult);
        } catch (error) {
            LogService.Error("CadAnuncios", "salvar", error)
            this.addErro(new OutputError({ message: "Erro ao salvar o registro" }))
        }
        finally {
            this.showLoading(false);
        }
    }
    //#endregion

    async carregarAnuncio() {

        this.clearErros();

        if (!this.id) {
            this.model = AnuncioModel.novo();
            return;
        }

        try {
            const output = await AnunciosService.consultarPorId(this.id);

            if (!output.isValid) {
                this.addErros(output.errorMessages);
                return;
            }

            if (!output.result) {
                this.model = AnuncioModel.novo();
                return;
            }

            this.model = output.result;
            this.title = `Anúncio #${this.model.id}`

        } catch (error) {
            this.addErro(new OutputError({ message: "Erro ao carregar o registro" }))
            LogService.Error("CadAnuncios", "carregarAnuncio", error)
        }
        finally {
            this.showLoading(false);
        }
    }

    carregarTipoAnuncio() {
        const tiposAnuncios = AnunciosService.consultarTiposAnuncios();
        tiposAnuncios.map(tipo => this.tipoAnuncios.push(tipo));

        this.tipoAnuncioSel = new TipoAnuncioModel();
        const findTipoAnuncio = this.tipoAnuncios.find((v) => v.id == this.model.tipoAnuncio)
        this.tipoAnuncioSel = findTipoAnuncio != undefined
            ? findTipoAnuncio
            : this.tipoAnuncios[0];
    }

    async carregarCidades() {

        try {
            const output = await AnunciosService.consultarCidades();

            if (!output.isValid) {
                this.addErros(output.errorMessages);
                return;
            }
            if (output.result == null) {
                return;
            }

            this.cidades = output.result
            const findCidade = this.cidades.find((f) => f.id == this.model.cidadeId);
            this.cidadeSel = findCidade != undefined ? findCidade : this.cidades[1];

        } catch (error) {
            this.addErro(new OutputError({ message: "Erro ao consultar as cidades" }))
            LogService.Error("CadAnuncios", "carregarCidades", error)
        }
        finally {
            this.showLoading(false);
        }

    }

    async carregarCategorias() {
        try {
            const output = await AnunciosService.consultarCategorias(true);

            if (!output.isValid) {
                this.addErros(output.errorMessages);
                return;
            }
            if (output.result == null) {
                return;
            }

            this.categorias = output.result;
            const findCat = this.categorias.find((f) => f.id == this.model.idCategoria);
            this.categoriaSel = findCat != undefined ? findCat : this.categorias[0];

        } catch (error) {
            this.addErro(new OutputError({ message: "Erro ao consultar as categorias" }))
            LogService.Error("CadAnuncios", "carregarCategorias", error)
        }
        finally {
            this.showLoading(false);
        }
    }

    clearImageFields() {
        this.fileBlob = "";
        this.fileBase64 = "";
        this.fileInput = {};
    }

    async excluirArquivoUpload() {

        if (!this.model || this.model.id == undefined || this.model.imagem == undefined) {
            this.addErro(new OutputError({ message: "Erro ao consultar as categorias" }))
            return;
        }

        const output = await AnunciosService.deletarImagem(this.model.imagem);

        if (!output.isValid) {
            this.addErros(output.errorMessages);
            return;
        }
        if (output.result == null) {
            return;
        }

        this.showSnackBar("Imagem excluída com sucesso.");
        this.clearImageFields();
    }

    async createUploadImagem(): Promise<IUploadImage | undefined> {

        if (!this.tipoAnuncioSel || this.tipoAnuncioSel.id < 2) {
            return;
        }

        if (!this.fileBase64 || this.fileBase64 == "") {
            return;
        }

        const uploadImagem: IUploadImage = {
            nomeArquivo: this.fileName,
            arquivoBase64: this.fileBase64
        }

        return uploadImagem;
    }

    async salvarDados(uploadImagem: IUploadImage | undefined) {

        this.clearErros();

        if (this.cidadeSel == null) {
            this.addErro(new OutputError({ message: "Cidade não informada" }))
            return;
        }

        this.model.cidadeId = this.cidadeSel.id ? this.cidadeSel.id : 0;
        this.model.idCategoria = this.categoriaSel.id ? this.categoriaSel.id : 0;
        this.model.tipoAnuncio = (this.tipoAnuncioSel) ? this.tipoAnuncioSel.id : 1;
        if (uploadImagem) {
            this.model.upload = uploadImagem
        }

        try {
            const output = await AnunciosService.salvar(this.model)

            if (!output.isValid) {
                this.showSnackBar("Não foi possível salvar o registro.");
                this.addErros(output.errorMessages);
                return;
            }

            this.showSnackBar("Registro salvo com sucesso.");
            this.voltar();

        } catch (error) {
            LogService.Error("CadAnuncios", "salvar", error)
            this.addErro(new OutputError({ message: "Erro ao salvar o dado" }))
            this.showSnackBar("Erro ao salvar o registro");
        }
    }

    voltar() {
        setTimeout(() => {
            this.$router.back();
        }, 1500);
    }
}

