import { Component, Watch } from "vue-property-decorator";
import { Debounce } from "vue-debounce-decorator";
import BaseComponent from "@/components/base-components/BaseComponent";
import DataHelper, { DateFormatType } from "@/utils/data-helper"
import AnunciosService from "@/services/anuncios.service";
import AnuncioModel from "@/models/AnuncioModel";
import CidadeModel from "@/models/CidadeModel";
import CategoriaModel from "@/models/CategoriaModel";
import TipoAnuncioModel from "@/models/TipoAnuncioModel";
import LogService from "@/services/log.service";
import IPesquisaDadosCommand from "@/models/types/IPesquisaDadosCommand";
import OutputError from "@/models/output/OutputError";

@Component
export default class Anuncios extends BaseComponent {

    private static cidadePref = "gdb_key_cidade_pref";
    private static categoriaPref = "gdb_key_categoria_pref";

    public pageIndex: number = 0;
    public hasMore: boolean = true;
    public cidades: Array<CidadeModel> = [];
    public cidadeSel: CidadeModel = new CidadeModel();

    //public idCategoria: number = 0;
    public categorias: Array<CategoriaModel> = [];
    public categoriaSel: CategoriaModel = new CategoriaModel();

    public tipoAnuncios: Array<TipoAnuncioModel> = [];
    public tipoAnuncioSel: TipoAnuncioModel = new TipoAnuncioModel(0, "Todos");

    public title: string = "Anúncios";
    public pesquisa: string = "";
    public dados: Array<AnuncioModel> = [];
    public modalFiltros: boolean = false;

    cadastro(item: any) {
        this.$router.push({
            name: "CadAnuncios",
            query: {
                id: item.id
            }
        });
    }

    async mounted() {
        this.resetConsulta();
        this.showLoading(true);
        this.consultarTipoAnuncios();
        await this.carregarCidades();
        await this.carregarCategorias();
        await this.consultar(false);
        this.infinetyScroll();

    }
    beforeDestroy() {
        window.onscroll = () => { };
    }

    resetConsulta() {
        this.pageIndex = 0;
        this.hasMore = true;
        this.dados = [];
    }

    infinetyScroll() {
        window.onscroll = () => {
            let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight;

            if (bottomOfWindow && this.hasMore) {
                this.pageIndex++;
                this.consultar();
            }
        };
    }

    async consultar(clearResults:boolean = false, clearErros: boolean = true) {

        this.showLoading(true);
        if (clearResults){
            this.dados = [];
        }
        if (clearErros) {
            this.clearErros();
        }
        const idCategoria = this.categoriaSel.id ? this.categoriaSel.id : 0;
        const idCidade = this.cidadeSel.id ? this.cidadeSel.id : 0;

        try {

            const command: IPesquisaDadosCommand = {
                pesquisa: this.pesquisa,
                idCidade,
                idCategoria,
                tipoAnuncio: this.tipoAnuncioSel.id,
                page: this.pageIndex
            };

            const output = await AnunciosService.consultar(command)

            if (!output.isValid) {
                this.addErros(output.errorMessages);
                return;
            }

            if (output.result && output.result.length == 0) {
                this.hasMore = false;
                return;
            }

            const dados = output.result != null ? output.result : [];
            this.dados = [...this.dados, ...dados]
            this.hasMore = true;

        } catch (error) {
            LogService.Error("Anuncios", "consultar", error)
            this.addErro(new OutputError({ message: "Erro ao consultar os dados" }))
        }
        finally {
            setTimeout(() => this.showLoading(false), 500);
        }
    }


    async carregarCidades() {

        try {
            const output = await AnunciosService.consultarCidades();

            if (!output.isValid) {
                this.addErros(output.errorMessages);
                return;
            }
            if (output.result == null) {
                return;
            }

            const cidades = output.result;
            this.cidades = cidades;
            this.cidadeSel = this.cidades[0];
            const strCidadePref = localStorage.getItem(Anuncios.cidadePref)
            if (strCidadePref && strCidadePref.length > 0) {
                this.cidadeSel = JSON.parse(strCidadePref);
            }

        } catch (error) {
            this.addErro(new OutputError({ message: "Erro ao consultar as cidades" }))
            LogService.Error("Anuncios", "carregarCidades", error)
        }
        finally {
            this.showLoading(false);
        }

    }
    async carregarCategorias() {
        try {
            const output = await AnunciosService.consultarCategorias(true);

            if (!output.isValid) {
                this.addErros(output.errorMessages);
                return;
            }
            if (output.result == null) {
                return;
            }

            const categorias = output.result
            this.categorias = categorias;
            this.categoriaSel = this.categorias[0];

            const strCategoriaPref = localStorage.getItem(Anuncios.categoriaPref)
            if (strCategoriaPref && strCategoriaPref.length > 0) {
                this.categoriaSel = JSON.parse(strCategoriaPref);
            }

        } catch (error) {
            this.addErro(new OutputError({ message: "Erro ao consultar as categorias" }))
            LogService.Error("Anuncios", "carregarCategorias", error)
        }
        finally {
            this.showLoading(false);
        }
    }
    consultarTipoAnuncios() {
        this.tipoAnuncios = AnunciosService.consultarTiposAnuncios();
    }
    async excluir(item: AnuncioModel) {

        const result = confirm(`Deseja excluir o registro "${item.sobrenome}, ${item.id}" ?`);
        if (result == false) {
            return;
        }

        this.showLoading(true);

        try {
            if (item.id) {
                const output = await AnunciosService.deletar(item.id);
                if (!output.isValid) {
                    this.addErros(output.errorMessages);
                    return;
                }

                const index = this.dados.indexOf(item);
                if (index > -1) {
                    this.dados.splice(index, 1)
                }
            }
        } catch (error) {
            LogService.Error("Anuncios", "excluir", error)
            this.addErro(new OutputError({ message: "Erro ao excluir o registro" }))
            this.showSnackBar("Erro ao excluir o registro");
        }
        finally{
            setTimeout(() => {
                this.showLoading(false);
            }, 500);
        }
    }
    get getFiltros() {

        const cidadeId = this.cidadeSel != null
            ? (this.cidadeSel.id ? this.cidadeSel.id : 0)
            : 0;

        let filtroCidade = cidadeId > 0
            ? `${this.cidadeSel.nome}`
            : '';

        let filtroCategoria = this.categoriaSel != null
            ? `${this.categoriaSel.nome}`
            : '';


        let filtroTipoAnuncio = this.tipoAnuncioSel != null
            ? `${this.tipoAnuncioSel.descricao}`
            : '';

        return `${filtroCidade} | ${filtroCategoria} | ${filtroTipoAnuncio}`
    }
    formatDate(unixTimeStamp: number) {

        return DataHelper.convertUnixTimeStampToDateFormat(unixTimeStamp, DateFormatType.DD_MM_YYYY);
    }
    getBRLDate(date: Date | undefined) {
        if (!date) {
            return "";
        }

        return DataHelper.formatDateByType(date, DateFormatType.DD_MM_YYYY_HH_MM_SS)
    }

    onModalFiltrosCancelarOnClick() {
        this.modalFiltros = !this.modalFiltros;
    }

    onModalFiltrosAplicarOnClick() {
        this.modalFiltros = false;
        this.consultar(true);

        localStorage.setItem(Anuncios.cidadePref, JSON.stringify(this.cidadeSel));
        localStorage.setItem(Anuncios.categoriaPref, JSON.stringify(this.categoriaSel));
    }

    @Watch("pesquisa")
    @Debounce(500)
    watchPesquisa(value: string, oldValue: string) {
        if (value.length > 1 || value.length == 0) {
            this.resetConsulta();
            this.consultar(true);
        }
    }
}
