/**
 * User model.
 */
export default class CategoriaModel {


    public id: number | undefined;
    public nome: string | undefined;
    public palavras: string | undefined;

    constructor(init?: Partial<CategoriaModel>) {
        if (init) {
            Object.assign(this, init);
        }
    }

}