import { Component, Watch } from "vue-property-decorator";
import { Debounce } from "vue-debounce-decorator";
import BaseComponent from "@/components/base-components/BaseComponent";
import UsuariosService from "@/services/usuarios.service";
import UsuariosModel from "@/models/UsuariosModel";
import DataHelper, { DateFormatType } from "@/utils/data-helper"
import LogService from "@/services/log.service";
import OutputError from "@/models/output/OutputError";

@Component
export default class Usuarios extends BaseComponent {

    public title: string = "Usuarios";
    public pesquisa: string = "";
    public dados: Array<UsuariosModel> = [];

    novoOnClick(item: any) {

        const data: any = {
            usuario: item,
            edicao: false
        }
        this.$router.push({ name: "CadUsuarios", params: data });
    }

    edicaoOnClick(item: any) {
        const data: any = {
            usuario: item,
            edicao: true
        }
        this.$router.push({ name: "CadUsuarios", params: data });
    }

    created() {
        this.consultar();
    }

    async consultar() {
        this.clearErros();
        this.showLoading(true);

        try {
            const output = await UsuariosService.consultar(this.pesquisa);

            if (!output.isValid) {
                this.addErros(output.errorMessages);
                return;
            }

            this.dados = output.result ? output.result : [];
        } catch (error) {
            LogService.Error("Usuários", "consultar", error)
            this.addErro(new OutputError({ message: "Erro ao consultar os dados" }))
            this.showSnackBar("Erro ao consultar as usuários");
        } finally {
            this.showLoading(false);
        }
    }

    async excluirOnClick(item: UsuariosModel) {
        const result = confirm(`Deseja excluir o registro "${item.email}" ?`);
        if (result == false) {
            return;
        }

        this.showLoading(true)
        try {
            if (item.id) {
                const output = await UsuariosService.deletar(item.id);

                if (!output.isValid) {
                    this.addErros(output.errorMessages);
                    return;
                }

                const index = this.dados.indexOf(item);
                if (index > -1) {
                    this.dados.splice(index, 1)
                }
            }
        } catch (error) {
            LogService.Error("Usuarios", "excluir", error)
            this.addErro(new OutputError({ message: "Erro ao excluir os dados" }))
            this.showSnackBar("Erro ao excluir o registro");
        }
        finally{
            setTimeout(() => {
                this.showLoading(false)
            }, 500);
        }
    }

    formatDate(unixTimeStamp: number | Date) {
        return DataHelper.convertUnixTimeStampToDateFormat(unixTimeStamp, DateFormatType.DD_MM_YYYY);
    }

    @Watch("pesquisa")
    @Debounce(500)
    watchPesquisa(value: string, oldValue: string) {
        if (value.length > 1 || value.length == 0) this.consultar();
    }
}
