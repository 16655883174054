import CidadeModel from "@/models/CidadeModel";
import Output from "@/models/output/Output";
import { ApiService } from "./api.service";
import LogService from "./log.service";


export default class CidadeService {
    
    public static async consultar(pesquisa:string) : Promise<Output<CidadeModel[]>> {
        try {
            
            const url = `/api/v1/cidade/?pesquisa=${pesquisa}`;            
            return await ApiService.get(url);
        } catch (err) {
            LogService.Error("CidadeService", "consultar", err)
            return Output.newOutputErrorMessage<CidadeModel[]>(null, "Não foi possível consultar as cidades");            
        }
    }

    public static async salvar(newItem:boolean, model: CidadeModel):Promise<Output<CidadeModel>> {
        try {
            if (newItem){
                return await this.add(model)
            }
            return await this.put(model)
                        
        } catch (err) {
            LogService.Error("CidadeService", "salvar", err)
            return Output.newOutputErrorMessage<CidadeModel>(null, "Não foi possível salvar a cidade")
        }
    }

    public static async add(model:CidadeModel): Promise<Output<CidadeModel>>{
        try {                
            const url = `/api/v1/cidade`;            
            return await ApiService.post(url, model);
        } catch (err) {
            LogService.Error("CidadeService", "add", err)
            return Output.newOutputErrorMessage<CidadeModel>(null, "Não foi possível inserir a cidade")
        }
    }
    
    public static async put(model:CidadeModel): Promise<Output<CidadeModel>>{
        try {            
            const url = `/api/v1/cidade/${model.id}`;            
            return await ApiService.put(url, model);
        } catch (err) {
            LogService.Error("CidadeService", "put", err)
            return Output.newOutputErrorMessage<CidadeModel>(null, "Não foi possível atualizar a cidade")
        }
    }

    public static async deletar(id: number): Promise<Output<boolean>>  {
        try {            
            const url = `/api/v1/cidade/${id}`;            
            return await ApiService.delete(url);
        } catch (err) {
            LogService.Error("CidadeService", "deletar", err)
            return Output.newOutputErrorMessage<boolean>(null, "Não foi possível excluir a cidade")
        }
    }        
}
