import { Component, Prop } from 'vue-property-decorator';
import BaseCadastro from '@/components/base-components/BaseCadastro';
import CategoriaModel from '@/models/CategoriaModel';
import CategoriaService from '@/services/categorias.service';
import LogService from '@/services/log.service';
import OutputError from '@/models/output/OutputError';

@Component
export default class CadCategorias extends BaseCadastro {

    @Prop() readonly data: CategoriaModel;

    public model: CategoriaModel = new CategoriaModel();
    public title: string = 'Cadastro de categoria';

    created() {
        this.model = { ...new CategoriaModel(), ...this.data };
    }


    async salvar() {
        this.clearErros();
        this.showLoading(true)

        try {
            const output = await CategoriaService.salvar(this.model)

            if (!output.isValid) {
                this.showSnackBar("Não foi possível salvar o registro.");
                this.addErros(output.errorMessages);
                return;
            }
            
            this.showSnackBar("Registro salvo com sucesso.");
            this.voltar();
        } catch (error) {
            LogService.Error("Categorias", "salvar", error)
            this.addErro(new OutputError({ message: "Erro ao salvar o registro" }))
            this.showSnackBar("Erro ao salvar o registro");
        }
        finally{
            setTimeout(() => {
                this.showLoading(false);
            }, 500);
        }
    }

    voltar() {
        setTimeout(() => {
            this.$router.back();
        }, 1500);
    }

    onBlurNome() {
        if (!this.model.palavras || this.model.palavras.length == 0) {
            this.model.palavras = this.model.nome;
        }
    }


}

