export default class ImagemHelper {

  public static blobToBase64(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        if (reader.result) {
          resolve(reader.result.toString());
        }
        else {
          return resolve("");
        }
      }
    });
  }

  public static resizeImage(file: File, maxWidth: number, maxHeight: number): Promise<Blob> {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.src = URL.createObjectURL(file);
      image.onload = () => {
        let width = image.width;
        let height = image.height;

        if (width <= maxWidth && height <= maxHeight) {
          resolve(file);
        }

        let newWidth;
        let newHeight;

        if (width > height) {
          newHeight = height * (maxWidth / width);
          newWidth = maxWidth;
        } else {
          newWidth = width * (maxHeight / height);
          newHeight = maxHeight;
        }

        let canvas = document.createElement('canvas');
        canvas.width = newWidth;
        canvas.height = newHeight;

        let context = canvas.getContext('2d');

        if (context != null) {
          context.drawImage(image, 0, 0, newWidth, newHeight);
        }

        canvas.toBlob((blob: Blob | null) => {
          resolve(blob as Blob);
        }, file.type);
      };
      image.onerror = reject;
    });
  }
}
