export const rules = {
    required: (value: any) => {                
        return !!value || "Requerido."
    },
    counter: (value: string | any[]) => value.length <= 20 || "Máximo de 20 characteres",
    pwd_min: (value: string | any[]) => {
        if (value) {
            return value.length >= 4 || 'Min 4 caracteres'
        }
        return false
    },
    data_dd_mm_yyyy: (value: string) => {

        if (value == "") {
            return true;
        }

        try {
            const spliData = value.split('/');

            const dia = Number.parseInt((spliData[0]) ? spliData[0] : "0");
            if (dia == 0 || (Number(dia) < 1 || Number(dia) > 31))
                return false || 'Data inválida';

            const mes = Number.parseInt((spliData[1]) ? spliData[1] : "0");
            if (mes == 0 || (mes < 1 || mes > 12))
                return false || 'Data inválida';

            const ano = Number.parseInt((spliData[2]) ? spliData[2] : "0");
            if (ano == 0 || ano < 1900)
                return false || 'Data inválida';

            const dateYMD = ano + "-" + mes + "-" + spliData[0];
            const date = Date.parse(dateYMD);
            return date != null;
        } catch (error) {
            return false || 'Data inválida';
        }
    },
    fone_min: (value: string | any[]) => {
        //54912341234
        //5412341234
        if (value) {
            return value.length >= 10 || 'Telefône incompleto'
        }
        return false
    },
    email: (value: string) => {
        if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "E-mail inválido.";
        }else
        return true;
    },
    email2: (value: string) => {
        if (!value || value.length == 0) {
            return true;
        }
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "E-mail inválido.";
    },
    number: (value: number) => {
        let valido = true;
        if (!isNaN(value)) {
            valido = false;
        }
        return !valido || "Formato inválido";
    },
    cnpj: (value: string) => {
        const msgErro = "CNPJ inválido";
        const cnpj = value.replace(/[^\d]+/g, '');

        if (cnpj == '') {
            return false || msgErro;
        }

        if (cnpj.length != 14) {
            return false || msgErro;
        }

        // Elimina CNPJs invalidos conhecidos
        if (cnpj == "00000000000000" ||
            cnpj == "11111111111111" ||
            cnpj == "22222222222222" ||
            cnpj == "33333333333333" ||
            cnpj == "44444444444444" ||
            cnpj == "55555555555555" ||
            cnpj == "66666666666666" ||
            cnpj == "77777777777777" ||
            cnpj == "88888888888888" ||
            cnpj == "99999999999999")
            return false || msgErro;

        // Valida DVs
        let tamanho = cnpj.length - 2
        let numeros = cnpj.substring(0, tamanho);
        let digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            const numPos = numeros.charAt(tamanho - i);
            soma += Number(numPos) * pos--;
            if (pos < 2)
                pos = 9;
        }
        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        const digtPos1 = digitos.charAt(0);
        if (resultado != Number(digtPos1))
            return false || msgErro;

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            const numPos = numeros.charAt(tamanho - i);
            soma += Number(numPos) * pos--;
            if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        const digtPos2 = digitos.charAt(0);
        if (resultado != Number(digtPos2))
            return false || msgErro;

        return true;
    },
    cpf: (value: string) => {
        const msgErro = "CPF inválido";
        let cpf = value.replace(/[^\d]+/g, '');
        if (cpf == '') return false || msgErro;

        // Elimina CPFs invalidos conhecidos	
        if (cpf.length != 11 ||
            cpf == "00000000000" ||
            cpf == "11111111111" ||
            cpf == "22222222222" ||
            cpf == "33333333333" ||
            cpf == "44444444444" ||
            cpf == "55555555555" ||
            cpf == "66666666666" ||
            cpf == "77777777777" ||
            cpf == "88888888888" ||
            cpf == "99999999999")
            return false || msgErro;
        // Valida 1o digito	
        let add = 0;
        for (let i = 0; i < 9; i++) { add += parseInt(cpf.charAt(i)) * (10 - i); }

        let rev = 11 - (add % 11);
        if (rev == 10 || rev == 11) { rev = 0; }
        if (rev != parseInt(cpf.charAt(9))) { return false || msgErro; }

        // Valida 2o digito	
        add = 0;
        for (let i = 0; i < 10; i++) { add += parseInt(cpf.charAt(i)) * (11 - i); }
        rev = 11 - (add % 11);
        if (rev == 10 || rev == 11) { rev = 0; }
        if (rev != parseInt(cpf.charAt(10))) { return false || msgErro; }

        return true;
    },
    phone_min: (value: string | any[]) => {
        if (value) {
            return value.length >= 13 || 'Mínimo 13 caracteres'
        }
        return false
    },
}

export default { rules }