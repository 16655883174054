/**
 * User model.
 */
export default class TipoAnuncioModel {

    
    public id: number;
    public descricao: string;
    
    constructor(id: number = 0, descricao:string = "") {
        this.id = id;
        this.descricao = descricao; 
    }    
}