import OutputError from "./OutputError"

export default class Output<T> {
    isValid: boolean
    result: T | null
    messages: string[] = []
    waningMessages: string[] = []
    errorMessages: OutputError[] = []


    static newOutputErrorMessage<T>(result:T|null, errorMessage:string):Output<T>{
        const output = new Output<T>();
        output.result = result;
        output.isValid= false;
        output.errorMessages.push(new OutputError({message:errorMessage}));
        return output;
    }
    
    static newOutputError<T>(result:T|null, error: OutputError):Output<T>{
        const output = new Output<T>();
        output.result = result;
        output.isValid = false;
        output.errorMessages.push(error);
        return output;
    }
}