import CategoriaModel from "@/models/CategoriaModel";
import Output from "@/models/output/Output";
import { ApiService } from "./api.service";
import LogService from "./log.service";


export default class CategoriaService {

    public static async consultar(pesquisa: string): Promise<Output<CategoriaModel[]>> {
        try {            
            const url = `/api/v1/categoria/?pesquisa=${pesquisa}`;
            return await ApiService.get(url);
        } catch (err) {
            LogService.Error("CategoriaService", "consultar", err)
            return Output.newOutputErrorMessage<CategoriaModel[]>([], "Não foi possível consultas as categorias")
        }
    }

    public static async salvar(model: CategoriaModel): Promise<Output<CategoriaModel>> {
        try {
            if (model.id == undefined) {
                return await this.add(model)
            }
            return await this.put(model)

        } catch (err) {
            LogService.Error("CategoriaService", "salvar", err)
            return Output.newOutputErrorMessage<CategoriaModel>(null, "Não foi possível salvar as categorias")
        }
    }

    private static async add(model: CategoriaModel): Promise<Output<CategoriaModel>> {
        try {            
            const url = `/api/v1/categoria`;
            return await ApiService.post(url, model);
        } catch (err) {
            LogService.Error("CategoriaService", "add", err)
            return Output.newOutputErrorMessage<CategoriaModel>(null, "Não foi possível inserir as categorias")
        }
    }

    private static async put(model: CategoriaModel): Promise<Output<CategoriaModel>> {
        try {            
            const url = `/api/v1/categoria/${model.id}`;
            return await ApiService.put(url, model);        
        } catch (err) {
            LogService.Error("CategoriaService", "put", err)
            return Output.newOutputErrorMessage<CategoriaModel>(null, "Não foi possível atualizar as categorias")
        }
    }

    public static async deletar(id: number): Promise<Output<boolean>> {
        try {            
            const url = `/api/v1/categoria/${id}`;            
            return await ApiService.delete(url);
        } catch (err) {
            LogService.Error("CategoriaService", "deletar", err)
            return Output.newOutputErrorMessage<boolean>(null, "Não foi possível excluir as categorias")
        }
    }
}
