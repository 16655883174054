import { Component, Vue } from 'vue-property-decorator';
import NavigatorDrawer from '@/components/navigator-drawer/NavigatorDrawer.vue'

@Component({
    components: {
        NavigatorDrawer
    }
})
export default class Home extends Vue {    

    private drawer: boolean = false;    
    public items: any = [];

    created(){
        const listRoutes = this.$router.options.routes || [];
        for (const iterator of listRoutes) {
            if (iterator.meta && iterator.meta.menu) {
                if (iterator.path == "/" || iterator.path == "/logout") continue;
                this.items.push({
                titulo: iterator.meta.title,
                descricao: iterator.meta.descricao,                
                path: iterator.path,
                });
            }
        }
    }
        

    public closeMenu(){        
        this.drawer = false;
        
    }

    public toggleMenu(): void {
        this.drawer = !this.drawer;        
    }
}
