
import { Component, Watch } from 'vue-property-decorator';
import { Debounce } from 'vue-debounce-decorator'
import BaseComponent from '@/components/base-components/BaseComponent';
import CategoriaService from '@/services/categorias.service';
import CategoriaModel from '@/models/CategoriaModel';
import LogService from '@/services/log.service';
import OutputError from '@/models/output/OutputError';


@Component
export default class Cidades extends BaseComponent {
    
    public title: string = 'Categorias';
    public pesquisa: string = "";
    public dados: Array<CategoriaModel> = [];

    editarOnClick(item: any) {
        this.$router.push(
            { name: 'CadCategorias', params: item });
    }

    created() {
        // this._service = new CategoriaService(this);
        this.consultar();
    }

    async consultar() {
        this.showLoading(true);
        this.clearErros();
        
        try {
            const output = await CategoriaService.consultar(this.pesquisa);            

            if (!output.isValid) {
                this.addErros(output.errorMessages);
                return;
            }
            
            this.dados = output.result ? output.result : [];

        } catch (error) {
            LogService.Error("Categorias", "consultar", error)
            this.addErro(new OutputError({ message: "Erro ao consultar os registros" }))
            this.showSnackBar("Erro ao consultar as categorias");
        }finally{
            this.showLoading(false);
        }
    }

    async excluir(model: CategoriaModel) {

        const result = confirm(`Deseja excluir o registro "${model.nome}" ?`)
        if (result == false) {
            return;
        }

        this.showLoading(true)
        try {
            if (model.id) {
                const output = await CategoriaService.deletar(model.id);

                if (!output.isValid) {
                    this.addErros(output.errorMessages);
                    return;
                }

                const index = this.dados.indexOf(model);
                if (index> -1){
                    this.dados.splice(index,1)
                }
            }
        } catch (error) {
            LogService.Error("Categorias", "excluir", error)
            this.addErro(new OutputError({ message: "Erro ao excluir o registro" }))
            this.showSnackBar("Erro ao excluir o registro");
        }
        finally{
            setTimeout(() => {
                this.showLoading(false);
            }, 500);
        }
    }

    @Watch("pesquisa")
    @Debounce(500)
    watchPesquisa(value: string, oldValue: string) {
        if (value.length > 1 || value.length == 0)
            this.consultar();

    }
}

