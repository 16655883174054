import LoginModel from '@/models/LoginModel';
import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

//Chaves do módulo e store
import { AUTHORIZED_USER } from "@/store/modules/auth/getters";
import { SIGN_IN } from "@/store/modules/auth/actions";

//Importação do módulo
import authStoreModule from '@/store/modules/auth';
import AuthService from "@/services/auth.service"
import LogService from '@/services/log.service';
import OutputError from '@/models/output/OutputError';
import BaseComponent from '@/components/base-components/BaseComponent';

//Criar instancia do módulo
const AuthStoreModule = namespace(authStoreModule.vuexName);

@Component
export default class Login extends BaseComponent {

    public title: string = 'Guia do bem admin login';
    public model: LoginModel = new LoginModel();

    @AuthStoreModule.Getter(AUTHORIZED_USER)
    public authorizedUser: LoginModel;

    @AuthStoreModule.Action(SIGN_IN)
    public signIn: (loginModel: LoginModel) => Promise<boolean>;

    public created() {
    }

    public async login() {

        this.clearErros();
        this.showLoading(true);

        try {
            const output = await AuthService.login({ email: this.model.email, senha: this.model.senha });

            if (!output.isValid) {
                this.addErros(output.errorMessages);
                return;
            }

            if (!output.result) {
                this.addErro(new OutputError({ message: "E-mail ou senha inválidos" }))
                return;
            }

            this.model.token = output.result.token ? output.result.token : '';
            this.signIn(this.model);
            this.$router.push("/");

            return;


        } catch (error) {
            LogService.Error("Login", "login", error)
            this.addErro(new OutputError({ message: "Sem conexão com o servidor" }))
            this.showSnackBar("Sem conexão com o servidor");
        } finally {
            setTimeout(() => {
                this.showLoading(false)
            }, 500);
        }
    }
}