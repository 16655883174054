import CidadeModel from '@/models/CidadeModel';
import CidadeService from '@/services/cidades.service';
import { Component, Watch } from 'vue-property-decorator';
import { Debounce } from 'vue-debounce-decorator'
import BaseComponent from '@/components/base-components/BaseComponent';
import LogService from '@/services/log.service';
import OutputError from '@/models/output/OutputError';


@Component
export default class Cidades extends BaseComponent {

    public title: string = 'Cidades';
    public pesquisa: string = "";
    public dados: Array<CidadeModel> = [];

    novoOnClick(item: any) {

        const data: any = {
            item,
            newItem: true
        };

        this.$router.push(
            { name: 'CadCidades', params: data });
    }

    editarOnClick(item: any) {
        const data: any = {
            item,
            newItem: false
        };
        this.$router.push(
            { name: 'CadCidades', params: data });
    }

    created() {
        this.consultar();
    }

    async consultar() {
        this.clearErros();
        this.showLoading(true);

        try {
            const output = await CidadeService.consultar(this.pesquisa);

            if (!output.isValid) {
                this.addErros(output.errorMessages);
                return;
            }

            this.dados = output.result ? output.result : [];
        } catch (error) {
            LogService.Error("Cidades", "consultar", error)
            this.addErro(new OutputError({ message: "Erro ao consultar os dados" }))
            this.showSnackBar("Erro ao consultar as cidades");
        } finally {
            this.showLoading(false);
        }
    }

    async excluirOnClick(model: CidadeModel) {
        const result = confirm(`Deseja excluir o registro "${model.nome}" ?`)
        if (result == false) {
            return;
        }

        this.showLoading(true);
        
        try {
            if (model.id) {
                const output = await CidadeService.deletar(model.id);
                if (!output.isValid) {
                    this.addErros(output.errorMessages);
                    return;
                }

                const index = this.dados.indexOf(model);
                if (index > -1) {
                    this.dados.splice(index, 1)
                }
            }
        } catch (error) {
            LogService.Error("Cidade", "excluir", error)
            this.addErro(new OutputError({ message: "Erro ao excluir o registro" }))
            this.showSnackBar("Erro ao excluir o registro");
        }
        finally{
            setTimeout(() => {
                this.showLoading(false);
            }, 500);
        }
    }

    @Watch("pesquisa")
    @Debounce(500)
    watchPesquisa(value: string, oldValue: string) {
        if (value.length > 1 || value.length == 0)
            this.consultar();

    }


}

