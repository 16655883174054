
import * as dotenv from "dotenv";
dotenv.config()

export default class LogService {
    private _vue: Vue;
    /**
     *
     */
    constructor(vue: Vue) {
        this._vue = vue;
    }

    public static Info(message:string, ...params:any[]){
        if (process.env.NODE_ENV == "production"){
            return;
        }
        /* eslint-disable no-console */
        console.info(message, params);
        /* eslint-enable no-console */
    }

    public static Warning(message:string, ...params:any[]){
        if (process.env.NODE_ENV == "production"){
            return;
        }
        
        /* eslint-disable no-console */        
        console.warn(message, params);
        /* eslint-enable no-console */
    }

    public static Error(message:string, ...params:any[]){
        if (process.env.NODE_ENV == "production"){
            return;
        }
        /* eslint-disable no-console */        
        console.error(message, params);
        /* eslint-enable no-console */
    }

    public static Log(message:string, ...params:any[]){
        if (process.env.NODE_ENV == "production"){
            return;
        }

        /* eslint-disable no-console */        
        console.log(message, params);
        /* eslint-enable no-console */        
    }

}
