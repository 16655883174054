import Output from "@/models/output/Output";
import UsuariosModel from "@/models/UsuariosModel";
import { ApiService } from "./api.service";
import LogService from "./log.service";

export default class UsuariosService {
    
    public static async consultar(pesquisa:string) : Promise<Output<UsuariosModel[]>> {
        try {            
            const url = `/api/v1/usuario/?pesquisa=${pesquisa}`;
            return await ApiService.get(url);
        } catch (err) {
            LogService.Error("UsuariosService", "consultar", err)
            return Output.newOutputErrorMessage<UsuariosModel[]>([], "Não foi possível consultar os usuários") 
        }
    }

    public static async salvar(model: UsuariosModel):Promise<Output<UsuariosModel>> {
        try {
            if (model.id == undefined){
                return await this.add(model)
            }
            return await this.put(model)
                        
        } catch (err) {
            LogService.Error("UsuariosService", "salvar", err)
            return Output.newOutputErrorMessage<UsuariosModel>(null, "Não foi possível salvar o registro") 
        }
    }

    private static async add(model:UsuariosModel): Promise<Output<UsuariosModel>>{
        try {                    
            const url = `/api/v1/usuario`;      
            return await ApiService.post(url, model);
        } catch (err) {
            LogService.Error("UsuariosService", "add", err)
            return Output.newOutputErrorMessage<UsuariosModel>(null, "Não foi possível inserir o registro") 
        }
    }
    
    private static async put(model:UsuariosModel): Promise<Output<UsuariosModel>>{
        try {            
            const url = `/api/v1/usuario/${model.id}`;            
            return await ApiService.put(url, model);
        } catch (err) {
            LogService.Error("UsuariosService", "put", err)
            return Output.newOutputErrorMessage<UsuariosModel>(null, "Não foi possível atualizar o registro") 
        }
    }

    public static async deletar(id: number): Promise<Output<boolean>> {
        try {            
            const url = `/api/v1/usuario/${id}`;            
            return await ApiService.delete(url);
        } catch (err) {
            LogService.Error("UsuariosService", "deletar", err)
            return Output.newOutputErrorMessage<boolean>(false, "Não foi possível excluir o registro") 
        }
    }    
}
