import axios, { AxiosError, AxiosInstance } from "axios";
import * as dotenv from 'dotenv' // see https://github.com/motdotla/dotenv#how-do-i-use-dotenv-with-import
import global from "@/config/global"
import Global from "@/config/global";
import LogService from "./log.service";
import Output from "@/models/output/Output";
dotenv.config()

export class ApiService {

    constructor() { }

    public static getAxios(): AxiosInstance {

        const url = `${Global.URL_API_V2}`;
        const token = localStorage.getItem(global.USER_LOCAL_STORAGE);
        const instance = axios.create({
            baseURL: url,
            timeout: 5000,
            headers: {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        });

        return instance;
    }

    public static async get<T>(url: string): Promise<Output<T>> {
        try {
            const axios = ApiService.getAxios();

            const response = await axios.get<Output<T>>(url);
            return response.data;
        } catch (err) {
            LogService.Error("GET", url, err)

            if (err instanceof AxiosError) {
                if (err && err.response && err.response.status == 400) {
                    const output: Output<T> = err.response.data;
                    return output;
                }
            }            
            throw err;
        }
    }

    public static async post<T>(url: string, data: any): Promise<Output<T>> {
        try {

            const axios = ApiService.getAxios();
            const response = await axios.post<Output<T>>(url, data);
            return response.data;
        } catch (err) {
            LogService.Error("POST", url, err)

            if (err instanceof AxiosError) {                
                if (err && err.response && err.response.status != 500) {
                    const output: Output<T> = err.response.data;
                    return output;
                }
            }
            throw err;
        }
    }

    public static async put<T>(url: string, data: any): Promise<Output<T>> {
        try {

            const axios = ApiService.getAxios();
            const response = await axios.put<Output<T>>(url, data);
            return response.data;
        } catch (err) {
            LogService.Error("PUT", url, err)
            if (err instanceof AxiosError) {
                if (err && err.response && err.response.status == 400) {
                    const output: Output<T> = err.response.data;
                    return output;
                }
            }
            throw err;
        }
    }

    public static async delete<T>(url: string): Promise<Output<T>> {
        try {

            const axios = ApiService.getAxios();
            const response = await axios.delete<Output<T>>(url);
            return response.data;
        } catch (err) {
            LogService.Error("DELETE", url, err)
            if (err instanceof AxiosError) {
                if (err && err.response && err.response.status == 400) {
                    const output: Output<T> = err.response.data;
                    return output;
                }
            }
            throw err;
        }
    }
}