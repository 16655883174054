import CidadeModel from "./CidadeModel";
import IUploadImage from "./types/IUploadImage";

/**
 * User model.
 */
export default class AnuncioModel {
  public id: number | undefined;
  public ativo: boolean | undefined;
  public tipoAnuncio: number | undefined;
  public tipoPessoa: string | undefined;
  public sobrenome: string | undefined;
  public nome: string | undefined;
  public descBusca: string | undefined;
  public endereco: string | undefined;
  public complemento: string | undefined;
  public telefone: string | undefined;
  public imagem: string | undefined;
  public imagemFullPath: string | undefined;
  public cidadeId: number | undefined;
  public cidade: CidadeModel | undefined;
  public idCategoria: number | undefined;
  public whatsapp: string | undefined;
  public facebook: string | undefined;
  public instagram: string | undefined;
  public website: string | undefined;
  public email: string | undefined;
  public ultimaAtualizacao: Date | undefined;
  public upload: IUploadImage | undefined;

  constructor(init?: Partial<AnuncioModel>) {
    if (init) {
      Object.assign(this, init);
    }
  }

  public static novo(): AnuncioModel {
    return new AnuncioModel(
      {
        ativo: true,
        tipoPessoa: 'pj'
      })
  }
}
