import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import { SHOW_LOADING, SHOW_SNACK_BAR } from '@/store/root/mutations';
import OutputError from '@/models/output/OutputError';

@Component
export default class BaseComponent extends Vue {

    //public erros: string[] = [];    
    public errorMessages: OutputError[] = []

    created() {
        this.errorMessages = [];
    }

    @Action(SHOW_SNACK_BAR)
    public showSnackBar: (text: string) => void;

    @Action(SHOW_LOADING)
    public showLoading: (value: boolean) => void;

    public isValid() { this.errorMessages.length == 0 }
    public clearErros() { this.errorMessages = [] }
    public addErro(error: OutputError) { this.errorMessages.push(error) }
    public addErros(errors: OutputError[]) { this.errorMessages.push(...errors); }

}

