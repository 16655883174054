import LogService from "@/services/log.service";

/**
 * User model.
 */
export default class UsuariosModel {
  public id: number;
  public ativo: boolean;
  public nome: string;
  public email: string;
  public senha: string;
  public dataRegistro: number;
  public ultimaAlteracao: Date;

  constructor() {
    this.id = 0;
    this.ativo = true;
    this.nome = "";
    this.email = "";
    this.dataRegistro =  0;
    this.ultimaAlteracao = new Date();
  }

  public static fromData(data: any) {
    const model = new UsuariosModel();
    model.id = data.id;
    model.ativo = data.ativo != undefined ? data.ativo : true;
    model.nome = data.nome;
    model.email = data.email;
    model.dataRegistro = data.dataRegistro;
    model.ultimaAlteracao = data.ultimaAlteracao;

    LogService.Info("UsuariosModel", data);
    return model;
  }

  public toJson() {
    return {
      id: this.id,
      ativo: this.ativo,
      nome: this.nome,
      senha: this.senha,
      email: this.email,
    };
  }
}
